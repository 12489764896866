import { createGlobalStyle } from "styled-components";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
button, a.button {
    min-width: 140px;
    height: 42px;
    padding: 0 20px;
    color: #000;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid #000;
    border-radius: 2px;
    cursor: pointer;    
    transition: opacity 0.2s;
    &.greyOutlines {
        background: none;
        color: ${theme.grey};
        border-color: ${theme.grey};
    }
    &:hover {
        opacity: 0.9;
    }
}    

    button.loading {        
        padding-right: 40px;
        position: relative;
    }
    button.loading:after {
        content: "";
        position: absolute;
        border-radius: 100%;
        right: 6px;
        top: 50%;
        width: 0px;
        height: 0px;
        margin-top: -2px;
        border: 2px solid rgba(255,255,255,0.5);
        border-left-color: #FFF;
        border-top-color: #FFF;
        animation: spin .6s infinite linear, grow .3s forwards ease-out;
    }
    button.loading.greyOutlines:after {
        border-color: rgba(0,0,0,0.25) !important;
        border-left-color: rgba(0,0,0,0.5) !important;
        border-top-color: rgba(0,0,0,0.5) !important;
    }
    @keyframes spin { 
        to {
            transform: rotate(359deg);
        }
    }
    @keyframes grow { 
        to {
            width: 14px;
            height: 14px;
            margin-top: -8px;
            right: 13px;
        }
    }
    #lonelySpinner {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 3px solid rgba(137,55,185,.6);
        border-radius: 50%;
        border-top-color: #fff;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
      }      
      @keyframes spin {
        to { -webkit-transform: rotate(360deg); }
      }
      @-webkit-keyframes spin {
        to { -webkit-transform: rotate(360deg); }
      }
`;

export default GlobalStyle;
