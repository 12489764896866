import React, { useState } from "react";
import ContentLoader from "react-content-loader";

export const MyLoader = () => (
  <ContentLoader
    speed={2}
    width="100%"
    viewBox="0 0 400 400"
    backgroundColor="#f2f2f2"
    foregroundColor="#fff"
  >
    <rect x="2" y="2" rx="0" ry="0" width="400" height="400" />
  </ContentLoader>
);

export const Coiniverse = () => {
  return (
    <svg
      width="32"
      height="34"
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M19.0662 5.4888L17.4996 2.929C15.7353 0.0474625 11.9616 -0.861478 9.06997 0.894869C6.18013 2.65297 5.26625 6.41356 7.0305 9.29334L8.00789 10.8897H15.76L19.0662 5.4888Z"
        fill="#F5F2EB"
      />
      <path
        opacity="0.5"
        d="M8.27613 23.1103L7.29874 24.7067C5.53448 27.5882 6.44837 31.347 9.33998 33.1051C12.2316 34.8632 16.0036 33.9525 17.7678 31.071L19.0663 28.9507L15.4902 23.1103H8.27613Z"
        fill="#F5F2EB"
      />
      <path
        opacity="0.5"
        d="M29.8564 23.1103C29.8564 23.1103 31.1832 25.3396 31.312 25.6683C32.3758 28.3776 31.3702 31.5386 28.7926 33.1051C25.901 34.8632 22.129 33.9525 20.3648 31.071L19.0663 28.9507L22.6424 23.1103H29.8564Z"
        fill="#F5F2EB"
      />
      <path
        d="M22.3742 10.8897L19.0663 5.48882L15.7583 10.8897H22.3742Z"
        fill="#F5F2EB"
      />
      <path
        opacity="0.5"
        d="M8.00794 10.8897H6.13077C2.74518 10.8897 0 13.6253 0 17.0009C0 20.3747 2.74518 23.1103 6.13077 23.1103H8.2761L11.8822 17.2189L8.00794 10.8897Z"
        fill="#F5F2EB"
      />
      <path
        d="M15.7583 10.8897H8.00793L11.884 17.2207L15.7583 10.8897Z"
        fill="#F5F2EB"
      />
      <path
        opacity="0.75"
        d="M15.7583 10.8897H8.00793L11.884 17.2207L15.7583 10.8897Z"
        fill="#F5F2EB"
      />
      <path
        opacity="0.75"
        d="M22.3742 10.8897L19.0663 5.48882L15.7583 10.8897H22.3742Z"
        fill="#F5F2EB"
      />
      <path
        opacity="0.75"
        d="M11.884 17.2206L8.27612 23.1103H15.4902L11.884 17.2206Z"
        fill="#F5F2EB"
      />
      <path
        opacity="0.75"
        d="M15.4901 23.1103L19.0663 28.9507L22.6424 23.1103H15.4901Z"
        fill="#F5F2EB"
      />
      <path
        d="M16.224 17.0009C16.224 13.6271 18.9692 10.8897 22.3548 10.8897H15.7583L11.8822 17.2189L15.4901 23.1103H22.3548C18.9692 23.1103 16.224 20.3747 16.224 17.0009Z"
        fill="#F5F2EB"
      />
      <path
        opacity="0.5"
        d="M30.1247 10.8897C30.1247 10.8897 31.5008 8.56022 31.6402 8.17695C32.607 5.50288 31.5908 2.43147 29.0626 0.894883C26.171 -0.863222 22.399 0.0474764 20.6348 2.92901L19.0681 5.48881L22.3761 10.8897H30.1247Z"
        fill="#F5F2EB"
      />
      <path
        d="M22.6424 21.6792C25.2352 21.6792 27.3371 19.5847 27.3371 17.0009C27.3371 14.4171 25.2352 12.3226 22.6424 12.3226C20.0496 12.3226 17.9478 14.4171 17.9478 17.0009C17.9478 19.5847 20.0496 21.6792 22.6424 21.6792Z"
        fill="#F5F2EB"
      />
    </svg>
  );
};
