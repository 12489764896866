import React, { useState } from "react";
import { css } from "@emotion/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    return (
      <div
        css={`
          .sliderBox {
            .imgItem {
              width: 100%;
              height: 100%;
              img {
                width: 500px;
                height: 500px;
                object-fit: contain;
                margin: 0 auto;
                @media (max-width: 1100px) {
                  width: 400px;
                  height: 400px;
                }
                @media (max-width: 900px) {
                  width: 500px;
                  height: 500px;
                }
                @media (max-width: 600px) {
                  width: 400px;
                  height: 400px;
                }
                @media (max-width: 470px) {
                  width: 300px;
                  height: 300px;
                }
                @media (max-width: 380px) {
                  width: 260px;
                  height: 260px;
                }
              }
            }
          }
          .navBox .slick-current {
            border: 1px solid #f1f1f1;
            box-shadow: 0px 2px 6px rgb(59 74 104 / 15%);
          }

          .navBox {
            margin-top: 30px;
            .slick-slide {
              margin-right: 15px;
              margin-bottom: 15px;

              width: 100px !important;
              padding: 10px;
              cursor: pointer;
            }
            > div {
              min-height: 100px;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
        `}
      >
        <div className="sliderBox">
          <Slider
            asNavFor={this.state.nav2}
            ref={slider => (this.slider1 = slider)}
            arrows={false}
            fade={true}
          >
            {this.props.data.map(item => {
              return (
                <div className="imgItem">
                  <img src={item.original} alt="Coin Image" />
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="navBox">
          <Slider
            asNavFor={this.state.nav1}
            ref={slider => (this.slider2 = slider)}
            slidesToShow={this.props.data.length}
            swipeToSlide={true}
            focusOnSelect={true}
          >
            {this.props.data.map(item => {
              return <img src={item.thumbnail} alt="Coin thumbnail" />;
            })}
          </Slider>
        </div>
      </div>
    );
  }
}

export default ImageGallery;
